<template>
  <div>
    <div class="mb-2 flex justify-end">
      <div
          class="d-flex smallGap text-secondaryColor align-items-center"
          role="button"
          @click="$router.go(-1)"
      >
        <mdicon :width="25" :height="25" name="arrow-left-thick"/>
        <p class="mb-0" v-text="$t(locale.back)"/>
      </div>
    </div>
    <b-card>
      <div class="d-flex justify-content-between align-items-center">
        <b-card-title class="mb-0">
          {{ $t(locale.updateSegment) }}
        </b-card-title>
        <!-- <LittleTabs :tabs="tabs" :switchTabs="switchTabs" /> -->
      </div>
    </b-card>
    <!-- <ValidationObserver ref="form">
        <KeepAlive>
            <transition name="zoom-fade" mode="out-in">
                <component :is="currentComponent" />
            </transition>
        </KeepAlive>
    </ValidationObserver> -->

    <ValidationObserver ref="segmentForm">
      <div v-show="tabSwitch === 1">
        <b-row>
          <b-col cols="12" lg="12">
            <SecondaryCard :title="$t(locale.details)">
              <b-container fluid>
                <b-row>
                  <b-col cols="12" lg="8">
                    <b-row>
                      <b-col cols="12" lg="4">
                        <b-form-group
                            :label="$t(locale.name)"
                            label-for="input-1"
                        >
                          <b-form-input
                              size="sm"
                              v-model="singleSegment.name"
                              :placeholder="$t(locale.name)"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" lg="4">
                        <validation-provider
                            #default="{errors}"
                            :rules="'required'"
                            :name="$t(locale.description)"
                        >
                          <b-form-group
                              :label="$t(locale.description)"
                              label-for="input-1"
                          >
                            <b-form-input
                                size="sm"
                                v-model="singleSegment.description"
                                :placeholder="$t(locale.description)"
                            />
                            <small
                                class="text-danger"
                                v-text="errors[0]"
                            />
                          </b-form-group>
                        </validation-provider>
                      </b-col>

                      <b-col cols="12" lg="4">
                        <b-form-group
                            :label="$t(locale.invoiceDiscount)"
                            label-for="input-1"
                        >
                          <b-form-input
                              size="sm"
                              v-model="singleSegment.invoice_discount"
                              type="number"
                              min="0"
                              :placeholder="$t(locale.invoiceDiscount)"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" lg="4">
                        <b-form-group
                            :label="$t(locale.shippingCharge)"
                            label-for="input-1"
                        >
                          <b-form-input
                              size="sm"
                              v-model="singleSegment.shipping_charge"
                              :placeholder="$t(locale.shippingCharge)"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" lg="4">
                        <b-form-group
                            :label="$t(locale.recurring)"
                            label-for="input-1"
                        >
                          <b-form-input
                              size="sm"
                              v-model="singleSegment.recurring_billing"
                              type="number"
                              min="0"
                              :placeholder="$t(locale.recurring)"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" lg="4">
                        <b-form-group
                            :label="$t(locale.invoiceCharge)"
                            label-for="input-1"
                        >
                          <b-form-input
                              size="sm"
                              v-model="singleSegment.invoice_charge"
                              :placeholder="$t(locale.invoiceCharge)"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" lg="4">
                        <b-form-group
                            :label="$t(locale.invoiceFreight)"
                            label-for="input-1"
                        >
                          <b-form-input
                              size="sm"
                              type="number"
                              min="0"
                              v-model="singleSegment.invoice_freight"
                              :placeholder="$t(locale.invoiceFreight)"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" lg="4">
                        <b-form-group
                            :label="$t(locale.invoiceAdminFee)"
                            label-for="input-1"
                        >
                          <b-form-input
                              size="sm"
                              type="number"
                              min="0"
                              v-model="singleSegment.invoice_administration_fee"
                              :placeholder="$t(locale.invoiceAdminFee)"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" lg="4">
                        <b-form-group
                            :label="$t(locale.currency)"
                            label-for="input-1"
                        >
                          <v-select
                              v-model="singleSegment.currency"
                              :placeholder="$t(locale.currency)"
                              @option:selected="computeSelect($event, 'currency')"
                              :options="currencies"
                              class="resource-selector select-size-sm d-inline-block w-full"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" lg="4">
                        <b-form-group
                            :label="$t(locale.costCenter)"
                            label-for="input-1"
                        >
                          <v-select
                              :placeholder="$t(locale.costCenter)"
                              :options="costCenter"
                              v-model="singleSegment.cost_center"
                              @option:selected="computeSelect($event, 'cost_center')"
                              class="resource-selector select-size-sm d-inline-block w-full"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" lg="4">
                        <b-form-group
                            :label="$t(locale.deliveryMethod)"
                            label-for="input-1"
                        >
                          <v-select
                              v-model="singleSegment.way_of_delivery"
                              :placeholder="$t(locale.deliveryMethod)"
                              :options="waysOfDelivery"
                              @option:selected="computeSelect($event, 'way_of_delivery')"
                              class="resource-selector select-size-sm d-inline-block w-full"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" lg="4">
                        <b-form-group
                            :label="$t(locale.project)"
                            label-for="input-1"
                        >
                          <v-select
                              :placeholder="$t(locale.project)"
                              :options="projects"
                              v-model="singleSegment.project"
                              @option:selected="computeSelect($event, 'project')"
                              class="resource-selector select-size-sm d-inline-block w-full"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" lg="4">
                        <b-form-group
                            :label="$t(locale.priceList)"
                            label-for="input-1"
                        >
                          <v-select
                              :placeholder="$t(locale.priceList)"
                              :options="priceLists"
                              v-model="singleSegment.price_list"
                              @option:selected="computeSelect($event, 'price_list')"
                              class="resource-selector select-size-sm d-inline-block w-full"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" lg="4">
                        <b-form-group
                            :label="$t(locale.paymentTerms)"
                            label-for="input-1"
                        >
                          <v-select
                              :placeholder="$t(locale.paymentTerms)"
                              :options="termsOfPayment"
                              v-model="singleSegment.terms_of_payment"
                              @option:selected="computeSelect($event, 'terms_of_payment')"
                              class="resource-selector select-size-sm d-inline-block w-full"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" lg="4">

                      </b-col>
                      <b-col cols="12" lg="4">

                      </b-col>
                      <b-col cols="12" lg="4">

                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col cols="12" lg="4">
                    <b-row>
                      <b-col cols="12" lg="6">
                        <b-form-group
                            :label="$t(locale.active)"
                            label-for="input-1"
                        >
                          <LittleTabs
                              :tabs="[
                                                        {
                                                            label: 'CustomersCardEdit.yes',
                                                            active: true,
                                                            value: true,
                                                        },
                                                        {
                                                            label: 'CustomersCardEdit.no',
                                                            active: false,
                                                            value: false,
                                                        },
                                                    ]"
                              v-model="singleSegment.active"
                              :defaultValue="true"
                              buttonSize="sm"
                              containerStyle="justify-content-start"
                              btnClass="tabWrapperSmall2"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" lg="6">
                        <b-form-group
                            :label="$t(locale.priceIncVat)"
                            label-for="input-1"
                        >
                          <LittleTabs
                              :tabs="[
                                                        {
                                                            label: 'CustomersCardEdit.yes',
                                                            active: true,
                                                            value: true,
                                                        },
                                                        {
                                                            label: 'CustomersCardEdit.no',
                                                            active: false,
                                                            value: false,
                                                        },
                                                    ]"
                              v-model="singleSegment.price_include_vat"
                              :defaultValue="true"
                              buttonSize="sm"
                              containerStyle="justify-content-start"
                              btnClass="tabWrapperSmall2"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" lg="6">
                        <b-form-group
                            :label="$t(locale.interestBilling)"
                            label-for="input-1"
                        >
                          <LittleTabs
                              :tabs="[
                                                        {
                                                            label: 'CustomersCardEdit.yes',
                                                            active: true,
                                                            value: true,
                                                        },
                                                        {
                                                            label: 'CustomersCardEdit.no',
                                                            active: false,
                                                            value: false,
                                                        },
                                                    ]"
                              v-model="singleSegment.interest_billing"
                              :defaultValue="true"
                              buttonSize="sm"
                              containerStyle="justify-content-start"
                              btnClass="tabWrapperSmall2"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" lg="6">
                        <b-form-group
                            :label="$t(locale.typeOfVat)"
                            label-for="input-1"
                        >
                          <select v-model="singleSegment.type_of_vat"
                                  class="bg-white focus:outline-none c-p-2 rounded-md flex justify-center custom-border border-gray-300 focus:border-blue-200">
                            <option value="SEVAT">SE</option>
                            <option value="SEREVERSEDVAT">SE reverse Vat</option>
                            <option value="EUREVERSEDVAT">EU reverse Vat</option>
                            <option value="EUVAT">Subject to EU VAT</option>
                            <option value="EXPORT">Export</option>
                          </select>
                          <!-- <v-select
                                  :placeholder="$t(locale.typeOfVat)"
                                  :options="[
                                      {
                                          label : 'SE',
                                          value : 'SEVAT'
                                      },
                                      {
                                          label : 'SE reverse Vat',
                                          value : 'SEREVERSEDVAT'
                                      },
                                      {
                                          label : 'EU reverse Vat',
                                          value : 'EUREVERSEDVAT'
                                      },
                                      {
                                          label : 'Subject to EU VAT',
                                          value : 'EUVAT'
                                      },
                                      {
                                          label : 'Export',
                                          value : 'EXPORT'
                                      },
                                  ]"
                                  v-model="singleSegment.type_of_vat"
                                  @option:selected="computeSelect($event, 'type_of_vat')"
                                  class="resource-selector select-size-sm d-inline-block w-full w-full"
                              /> -->
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" lg="6">
                        <b-form-group
                            :label="$t(locale.salesAccount)"
                            label-for="input-1"
                        >
                          <v-select class="w-full select-size-sm" v-model="singleSegment.sales_account" label="number"
                                    @option:selected="changeAccounts($event, 'sales_account')" :filterable="false"
                                    :options="accountResults" @search="searchAccounts">
                            <template slot="no-options">
                              Sök...
                            </template>
                            <template slot="option" slot-scope="option">
                              <div class="d-center">
                                {{ option.number }}
                              </div>
                            </template>
                            <template slot="selected-option" slot-scope="option">
                              <div class="selected d-center">
                                {{ option.number }}
                              </div>
                            </template>
                          </v-select>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-container>
            </SecondaryCard>
          </b-col>

          <b-col cols="12" lg="8">
            <SecondaryCard :title="$t(locale.invoiceDistribution)" class="mt-2">
              <b-container fluid>
                <b-row>
                  <b-col cols="12" lg="4">
                    <div class="flex flex-col">
                      <!-- <p
                          class="h4 mb-2"
                          v-text="$t(locale.invoice)"
                      /> -->
                      <div class="w-full">
                        <b-form-group
                            :label="$t(locale.invoice)"
                            label-for="input-1"
                        >
                          <LittleTabs
                              :tabs="[
                                                            {
                                                                label: 'InvoiceCardCustomerEdit.print',
                                                                active: true,
                                                                value: 'print',
                                                            },
                                                            {
                                                                label: 'CustomersCardEdit.firstForm.email',
                                                                active: false,
                                                                value: 'email',
                                                            },
                                                            {
                                                                label: 'InvoiceCardCustomerEdit.einvoice',
                                                                active: false,
                                                                value: 'einvoice',
                                                            },
                                                        ]"
                              buttonSize="sm"
                              containerStyle="justify-content-start"
                              btnClass="tabWrapperSmall2"
                              v-model="singleSegment.invoice"
                              :defaultValue="true"
                          />
                        </b-form-group>
                      </div>
                      <div class="w-full">
                        <b-form-group
                            :label="$t(locale.invoiceText)"
                            label-for="input-1"
                        >
                          <b-form-textarea
                              v-model="singleSegment.invoice_text"
                              :placeholder="$t(locale.invoiceText)"
                          />
                        </b-form-group>
                      </div>
                    </div>
                  </b-col>

                  <b-col cols="12" lg="4">
                    <div class="flex flex-col">
                      <!-- <p
                          class="h4 mb-2"
                          v-text="$t(locale.quote)"
                      /> -->
                      <div class="w-full">
                        <b-form-group
                            :label="$t(locale.quote)"
                            label-for="input-1"
                        >
                          <LittleTabs
                              :tabs="[
                                                            {
                                                                label: 'InvoiceCardCustomerEdit.print',
                                                                active: true,
                                                                value: 'print',
                                                            },
                                                            {
                                                                label: 'CustomersCardEdit.firstForm.email',
                                                                active: false,
                                                                value: 'email',
                                                            },
                                                        ]"
                              buttonSize="sm"
                              containerStyle="justify-content-start"
                              btnClass="tabWrapperSmall2"
                              v-model="singleSegment.quote"
                              :defaultValue="true"
                          />
                        </b-form-group>
                      </div>
                      <div class="w-full">
                        <b-form-group
                            :label="$t(locale.notes)"
                            label-for="input-1"
                        >
                          <b-form-textarea
                              v-model="singleSegment.notes"
                              :placeholder="$t(locale.notes)"
                          />
                        </b-form-group>
                      </div>
                    </div>
                  </b-col>

                  <b-col cols="12" lg="4">
                    <div class="flex flex-col">
                      <!-- <p
                          class="h4 mb-2"
                          v-text="$t(locale.order)"
                      /> -->
                      <div class="w-full">
                        <b-form-group
                            :label="$t(locale.order)"
                            label-for="input-1"
                        >
                          <LittleTabs
                              :tabs="[
                                                            {
                                                                label: 'InvoiceCardCustomerEdit.print',
                                                                active: true,
                                                                value: 'print',
                                                            },
                                                            {
                                                                label: 'CustomersCardEdit.firstForm.email',
                                                                active: false,
                                                                value: 'email',
                                                            },
                                                        ]"
                              buttonSize="sm"
                              containerStyle="justify-content-start"
                              btnClass="tabWrapperSmall2"
                              v-model="singleSegment.order"
                              :defaultValue="true"
                          />
                        </b-form-group>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-container>
            </SecondaryCard>
          </b-col>

          <b-col cols="12" lg="12">
            <div class="shadow leftCardBottomBorder mt-2">
              <div class="flex justify-between w-100 bg-secondaryColor p-2 text-white leftCardTopBorder">
                <p
                    class="mb-0 h2 font-weight-bold"
                    style="color: inherit"
                    v-text="$t(locale.customers)"
                />
                <div class="flex gap-2">
                  <button
                      v-if="markCustomers.length > 0"
                      @click="openRemoveCustomerModal({}, true)"
                      class="flex justify-center font-semibold text-white focus:outline-none rounded-md c-p-3 bg-red-500"
                      v-text="$t(locale.removeCustomer)"
                  />
                  <button
                      class="flex justify-center font-semibold bg-white focus:outline-none rounded-md c-p-3 text-theme-sidebar"
                      v-text="$t(locale.addCustomer)"
                      @click="tabSwitch = 2"
                  />
                </div>
              </div>

              <b-container class="p-1 bg-white rounded-bottom">
                <table class="table w-full z-50 relative mb-7">
                  <thead class="">
                  <tr>
                    <th class="text-left whitespace-nowrap p-2 md:p-0">
                                                <span class="text-xs text-gray-400">
                                                    <b-form-checkbox
                                                        v-model="selected"
                                                        @input="performAllChecked"
                                                        :value="checked"
                                                    />
                                                </span>
                    </th>
                    <th class="text-left whitespace-nowrap p-2 md:p-0"><span
                        class="text-xs text-gray-400">{{ $t(locale.customerNumber) }}</span></th>
                    <th class="text-left whitespace-nowrap p-2 md:p-0"><span
                        class="text-xs text-gray-400">{{ $t(locale.cname) }}</span></th>
                    <th class="text-left whitespace-nowrap p-2 md:p-0"><span
                        class="text-xs text-gray-400">{{ $t(locale.organizationLabel) }}</span></th>
                    <th class="text-left whitespace-nowrap p-2 md:p-0"><span
                        class="text-xs text-gray-400">{{ $t(locale.customerType) }}</span></th>
                    <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-400">Action</span>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="customer in customerResults" :key="customer.uuid">
                    <td>
                                                <span>
                                                    <b-form-checkbox @change="performSingleChecked"
                                                                     v-model="customer.checked"/>
                                                </span>
                    </td>
                    <td><span>{{ customer.customer_number }}</span></td>
                    <td><span>{{ customer.name }}</span></td>
                    <td><span>{{ customer.organisation_number }}</span></td>
                    <td><span>{{ customer.type }}</span></td>
                    <td>
                                                <span @click="openRemoveCustomerModal(customer)">
                                                    <mdicon
                                                        class="text-danger"
                                                        role="button"
                                                        :width="20"
                                                        :height="20"
                                                        name="trash-can"
                                                    />    
                                                </span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </b-container>
            </div>
          </b-col>
        </b-row>
      </div>

      <div v-show="tabSwitch === 2">
        <b-row>
          <b-col cols="12" lg="12">
            <div class="shadow leftCardBottomBorder mt-2">
              <div class="flex justify-between w-100 bg-secondaryColor p-2 text-white leftCardTopBorder">
                <p
                    class="mb-0 h2 font-weight-bold"
                    style="color: inherit"
                    v-text="$t(locale.customers)"
                />
              </div>

              <b-container class="p-1 bg-white rounded-bottom">
                <div class="grid grid-cols-1 md:grid-cols-1 gap-3">
                  <div class="scrol-area table-responsive flex flex-col custom-border c-p-1 rounded">
                    <h3>Customers</h3>
                    <table class="table w-full z-50 relative mb-7">
                      <thead class="">
                      <tr>
                        <th class="text-left whitespace-nowrap p-2 md:p-0">
                                                        <span class="text-xs text-gray-400">
                                                            <b-form-checkbox
                                                                v-model="selected"
                                                                @input="performAllCustomerChecked"
                                                                :value="checked"
                                                            />
                                                        </span>
                        </th>
                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span
                            class="text-xs text-gray-400">{{ $t(locale.customerNumber) }}</span></th>
                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span
                            class="text-xs text-gray-400">{{ $t(locale.name) }}</span></th>
                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span
                            class="text-xs text-gray-400">{{ $t(locale.organizationLabel) }}</span></th>
                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span
                            class="text-xs text-gray-400">{{ $t(locale.segment) }}</span></th>
                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span
                            class="text-xs text-gray-400">{{ $t(locale.billedSince) }}</span></th>
                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span
                            class="text-xs text-gray-400">{{ $t(locale.totalBilled) }}</span></th>
                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span
                            class="text-xs text-gray-400">{{ $t(locale.customerType) }}</span></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="customer in allCustomers.data" :key="customer.uuid">
                        <td>
                                                        <span>
                                                            <b-form-checkbox @change="performAllSingleChecked"
                                                                             v-model="customer.checked"/>
                                                        </span>
                        </td>
                        <td><span>{{ customer.customer_number }}</span></td>
                        <td><span>{{ customer.name }}</span></td>
                        <td><span>{{ customer.org_pers_number }}</span></td>
                        <td><span v-if="customer.segment">{{ customer.segment.segment_name }}</span></td>
                        <td><span>{{ customer.billed_since }}</span></td>
                        <td><span>{{ customer.total_billed }}</span></td>
                        <td><span>{{ customer.type }}</span></td>
                      </tr>
                      </tbody>
                    </table>

                  </div>
                  <div class="scrol-area table-responsive flex flex-col custom-border c-p-1 rounded"
                       style="display: none !important;">
                    <h3>Current</h3>
                    <table class="table w-full z-50 relative mb-7">
                      <thead class="">
                      <tr>
                        <th class="text-left whitespace-nowrap p-2 md:p-0">
                                                        <span class="text-xs text-gray-400">
                                                            <b-form-checkbox
                                                                v-model="selectedCustomerSegment"
                                                                @input="performAllSegmentChecked"
                                                                :value="checked"
                                                            />
                                                        </span>
                        </th>
                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span
                            class="text-xs text-gray-400">{{ $t(locale.customerNumber) }}</span></th>
                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span
                            class="text-xs text-gray-400">Segment</span></th>
                        <!-- <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-400">{{ $t(locale.cname) }}</span></th> -->
                        <!-- <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-400">{{ $t(locale.organizationLabel) }}</span></th>
                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-400">{{ $t(locale.customerType) }}</span></th> -->
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="customer in segmentCustomers" :key="customer.uuid">
                        <td>
                                                        <span>
                                                            <b-form-checkbox @change="performAllSingleSegmentChecked"
                                                                             v-model="customer.checked"/>
                                                        </span>
                        </td>
                        <td><span>{{ customer.customer_number }}</span></td>
                        <td><span>{{ customer.segment.segment_name }}</span></td>
                        <!-- <td><span>{{ customer.name }}</span></td>
                        <td><span>{{ customer.organisation_number }}</span></td>
                        <td><span>{{ customer.type }}</span></td> -->
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </b-container>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-col cols="12" class="mt-5" v-if="tabSwitch === 2">
        <div class="flex flex-col">
          <div class="mb-3">
            <div class="w-full flex justify-end">
              <paginate
                  v-model="currentPage"
                  :page-count="allCustomers.total_pages"
                  :click-handler="firePagination"
                  :prev-text="'<'"
                  :next-text="'>'"
                  :page-class="'page-item c-mr-1 border-unset'"
                  prev-link-class='page-link c-mr-1 border-unset'
                  next-link-class='page-link c-mr-1 border-unset'
                  next-class='next-item'
                  prev-class='prev-item'
                  page-link-class='page-link c-mr-1 border-unset'
                  active-class='active c-mr-1 border-unset'
                  :container-class="'pagination  b-pagination'">
              </paginate>
            </div>
          </div>
        </div>
        <b-row>
          <b-col cols="12" lg="8"></b-col>
          <b-col cols="12" lg="4">
            <b-row>
              <b-col cols="12" lg="6">
                <button
                    v-if="markAllCustomers.length > 0 || this.markAllSegment.length > 0"
                    @click="openAddModal()"
                    class="flex whitespace-nowrap justify-center font-semibold text-white focus:outline-none rounded-md c-p-3 bg-theme-sidebar"
                    v-text="$t(locale.addCustomer)"
                />
              </b-col>
              <b-col cols="12" lg="6">
                <b-button
                    @click="tabSwitch = 1"
                    block
                    variant="primary"
                    v-text="$t(locale.back)"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>

      <b-col cols="12" v-if="tabSwitch === 1">
        <b-row align-h="between" class="smallGap mt-1">
          <b-col cols="12" lg="3">
            <b-button
                block
                variant="danger"
                @click="$bvModal.show('modal-remove-segment')"
                v-text="$t(locale.deleteSegment)"
            />
          </b-col>
          <b-col
              cols="12"
              lg="4"
              class="d-flex justify-content-between justify-lg-end"
          >
            <div class="">
              <b-button
                  block
                  variant="outline-danger"
                  @click="$router.go(-1)"
                  v-text="$t(locale.cancel)"
              />
            </div>
            <div class="">
              <b-button
                  @click="updateCustomerSegment"
                  block
                  variant="primary"
                  v-text="processing ? $t('Message.loading') : $t(locale.updateSegment)"
              />
            </div>
          </b-col>
        </b-row>
      </b-col>
    </ValidationObserver>

    <Modal
        id="modal-remove-segment"
        :hideFooter="true"
        :title="'Remove Customer Segment'"
    >
      <b-card-text
          class="d-flex flex-column text-center align-items-center justify-content-center"
          style="gap: 1rem"
      >
        <img
            src="@/assets/duplioAsset/icons/warningCircle.png"
            width="70px"
            height="70px"
        />
        <p class="mb-0 pb-0">
          Är du säker på att du vill radera artikelsegmentet?
        </p>
      </b-card-text>
      <div class="flex justify-end gap-3 mt-2">
        <b-button
            variant="outline-danger"
            size="md"
            @click="$bvModal.hide('modal-remove-segment')"
        >
                <span
                    v-text=" $t('Message.cancel')"/>
        </b-button>
        <b-button
            variant="primary"
            size="md"
            :disabled="sendingModalAction"
            @click="removeSegment"
        >
                <span
                    v-text="sendingModalAction ? $t('Message.loading') :  $t('Message.proceed')"
                />
        </b-button>
      </div>
    </Modal>

    <Modal
        id="modal-remove-customer-segment"
        :hideFooter="true"
        :title="'Remove Customer'"
    >
      <b-card-text
          class="d-flex flex-column text-center align-items-center justify-content-center"
          style="gap: 1rem"
      >
        <img
            src="@/assets/duplioAsset/icons/warningCircle.png"
            width="70px"
            height="70px"
        />
        <p class="mb-0 pb-0" v-if="single">
          Är du säker på att du vill ta bort kund med kundnummer <b>{{ customerSelected.customer_number }}</b>
          från detta segment?
        </p>
        <p class="mb-0 pb-0" v-if="bulk">
          The following will be removed; <br>
        <ul>
          <li v-for="item in markCustomers" :key="item.uuid"> Kund : <b>{{ item.customer_number }}</b></li>
        </ul>
        <!-- <b>{{ customerSelected.customer_number }}</b> from this segment? -->
        </p>
      </b-card-text>
      <div class="flex justify-end gap-3 mt-2">
        <b-button
            variant="outline-danger"
            size="md"
            @click="$bvModal.hide('modal-remove-customer-segment')"
        >
                <span
                    v-text=" $t('Message.cancel')"/>
        </b-button>
        <b-button
            variant="primary"
            size="md"
            :disabled="sendingModalAction"
            @click="removeCustomerFromSegment"
        >
                <span
                    v-text="sendingModalAction ? $t('Message.loading') :  $t('Message.proceed')"
                />
        </b-button>
      </div>
    </Modal>

    <Modal
        id="modal-add-customer-segment"
        :hideFooter="true"
        :title="'Add Customers'"
    >
      <b-card-text
          class="d-flex flex-column"
          style="gap: 1rem"
      >
        <!-- <img
            src="@/assets/duplioAsset/icons/warningCircle.png"
            width="70px"
            height="70px"
        /> -->
        <p class="mb-0 pb-0">
          The following will be added to segments; <br>
        <ul>
          <li v-for="item in markAllCustomers" :key="item.uuid"> Kund : <b>{{ item.customer_number }}</b></li>
          <li v-for="item in markAllSegment" :key="item.uuid"> Kund : <b>{{ item.customer_number }}</b></li>
        </ul>
        </p>
      </b-card-text>
      <div class="flex justify-end gap-3 mt-2">
        <b-button
            variant="outline-danger"
            size="md"
            @click="$bvModal.hide('modal-add-customer-segment')"
        >
                <span
                    v-text=" $t('Message.cancel')"/>
        </b-button>
        <b-button
            variant="primary"
            size="md"
            :disabled="sendingModalAction"
            @click="addCustomersToSegment"
        >
                <span
                    v-text="sendingModalAction ? $t('Message.loading') :  $t('Message.proceed')"
                />
        </b-button>
      </div>
    </Modal>
    <Modal

        id="modal-access-denied"
        :hideFooter="true"
        :no-close-on-backdrop="true"
        :hide-header="true"
        :hide-back-drop="true"
    >
      <access-denied/>
    </Modal>
  </div>
</template>

<script>
import basic from '@/@core/components/editCustomer/basic.vue';
import invoice from '@/@core/components/editCustomer/invoice.vue';
import vSelect from 'vue-select';
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import {getCountries} from './constants/countries'
import useAppConfig from '@core/app-config/useAppConfig';
import Paginate from 'vuejs-paginate'
import {mapGetters} from "vuex"
import debounce from 'lodash.debounce';
import accessDenied from "@core/components/permission/accessDenied";

export default {
  name: 'updateCustomerSegment',
  components: {
    'paginate': Paginate,
    basic,
    invoice,
    ValidationObserver,
    ValidationProvider,
    vSelect, accessDenied
  },
  data() {
    return {
      config: useAppConfig(),
      tabSwitch: 1,
      singleSegment: {},
      customerResults: [],
      processing: false,
      countries: [...getCountries()],
      currentComponent: 'basic',
      tabs: [
        {
          label: 'CustomersCardEdit.basic',
          component: 'basic',
          active: true,
        },
        {
          label: 'CustomersCardEdit.invoice',
          component: 'invoice',
          active: false,
        },
      ],
      selected: false,
      selectedCustomerSegment: false,
      checked: "selected",
      locale: {
        segment: "articleSectionCard.segment",
        back: "articleSectionCard.backOverview",
        tabs: 'CustomersCard',
        active: 'CustomersCardEdit.firstForm.active',
        notes: 'CustomersCardEdit.forthForm.notes',

        customerNumber: 'CustomersCardEdit.firstForm.custnumber',
        cname: 'CustomersCardEdit.firstForm.name',
        organizationLabel: 'CustomersCardEdit.firstForm.organisationLabel',
        customerType: 'CustomersCardEdit.firstForm.customerType',


        invoiceDetails: 'CustomersCardEdit.invoice',

        interestBilling: 'InvoiceCardCustomerEdit.interestBilling',
        invoicing: 'InvoiceCardCustomerEdit.invoicing',
        shippingCharge: 'InvoiceCardCustomerEdit.shippingCharge',
        priceVat: 'InvoiceCardCustomerEdit.priceVat',
        invoiceDistribution: 'InvoiceCardCustomerEdit.invoiceDistribution',
        print: 'InvoiceCardCustomerEdit.print',
        quote: 'InvoiceCardCustomerEdit.quote',
        invoiceText: 'InvoiceCardCustomerEdit.invoiceText',
        order: 'InvoiceCardCustomerEdit.order',
        invoice: 'InvoiceCardCustomerEdit.invoice',
        details: 'InvoiceCardCustomerEdit.details',
        delete: 'CustomersCardEdit.dltBtn',
        cancel: 'CustomersCardEdit.cancel',
        priceInvat: 'invoicesEditCard.priceIncVat',

        name: "CustomerSegment.name",
        description: "CustomerSegment.description",
        recurring: "CustomerSegment.recurring",
        billedSince: "CustomerSegment.billedSince",
        totalBilled: "CustomerSegment.totalBilled",
        priceIncVat: "CustomerSegment.priceIncVat",
        invoiceDiscount: "CustomerSegment.invoiceDiscount",
        invoiceAdminFee: "CustomerSegment.invoiceAdminFee",
        invoiceFreight: "CustomerSegment.invoiceFreight",
        invoiceCharge: "CustomerSegment.invoiceCharge",
        typeOfVat: "CustomerSegment.typeOfVat",
        updateSegment: 'CustomerSegment.updateSegment',
        deleteSegment: 'CustomerSegment.deleteSegment',
        paymentTerms: 'InvoiceCardCustomerEdit.paymentTerms',
        deliveryTerms: 'InvoiceCardCustomerEdit.deliveryTerms',
        deliveryMethod: 'InvoiceCardCustomerEdit.deliveryMethod',
        costCenter: 'createContract.costCenter',
        project: 'createContract.project',
        priceList: 'invoicesEditCard.priceList',
        currency: 'InvoiceCardCustomerEdit.currency',
        salesAccount: 'InvoiceCardCustomerEdit.salesAccount',
        customers: 'StatisticsCard.tabs.customers',
        createCustomer: 'CustomersCardEdit.createCustomer',
        addCustomer: 'CustomersCardEdit.addCustomer',
        removeCustomer: 'CustomersCardEdit.removeCustomer',
      },
      waysOfDelivery: [],
      deliveryTerms: [],
      termsOfPayment: [],
      printTemplates: [],
      priceLists: [],
      currencies: [],
      costCenter: [],
      projects: [],
      accountResults: [],
      printTemplateInvoice: [],
      printTemplateOffer: [],
      printTemplateOrder: [],
      printTemplateCashInvoice: [],
      sendingModalAction: false,
      customerSelected: {},
      markCustomers: [],
      single: false,
      bulk: false,
      params: {
        customize: 'no',
      },
      allCustomers: {data: [], total_pages: 1},
      customers: {data: [], total_pages: 1},
      markAllCustomers: [],
      markAllSegment: [],
      segmentCustomers: [],
      currentPage: 1,

    };
  },
  watch: {
    tabSwitch: function (n, o) {
      if (n === 1) {
        const {id} = this.$route.params
        if (id) {
          this.getSingleSegment(id)
        }
      }
    }
  },
  computed: {
    message() {
      return this.GET_LOCALE === 'sv' ? 'message' : 'eng_message';
    },
    features() {
      if (this.getLang == '') {
        return this.swedishLang ? 'features' : 'features';
      } else return this.getLang == 'sv' ? 'features' : 'features';
    },
    getLang() {
      return this.config.lang.value;
    },
    ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    })
  },
  methods: {
    openAccessDeniedModal() {
      this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', true)
      this.$bvModal.show("modal-access-denied");

    },
    switchTabs(tabIndex) {
      this.tabs.find(item => item.active == true).active = false;

      this.currentComponent = this.tabs[tabIndex].component;
      this.tabSwitch = this.tabs[tabIndex].component === 'invoice' ? 2 : 1

      this.tabs[tabIndex].active = true;
    },
    getSingleSegment(uuid) {
      this.$useJwt.customSegment({URL: `/customers/${uuid}`, method: 'get'})
          .then(res => {
            this.singleSegment = res.data
            this.customerResults = this.singleSegment.customers.map(el => {
              return {
                ...el,
                checked: false
              }
            })
          }).catch(err => {
        if (err.response.status === 403) {
          window.Bus.$emit('trigger-access-denied', {'data': err.response.data})
        }
      })
    },
    customerSegments() {
      this.$useJwt.customSegment({URL: '/info/customers', method: 'get'})
          .then(res => {
            let {customers_not_in_segment, customers_in_segment} = res.data.data

            customers_not_in_segment = [...customers_not_in_segment, ...customers_in_segment]
            customers_not_in_segment.map(item => {
              item.checked = false
            })
            this.allCustomers.data = customers_not_in_segment
            // this.allCustomers = {
            //   ...this.allCustomers, data: customers_not_in_segment.map(el => {
            //     return {
            //       ...el,
            //       checked: false
            //     }
            //   })
            // }
            const {id} = this.$route.params
            this.segmentCustomers = customers_in_segment.filter(el => el.segment.segment_uuid !== id)
            // this.segmentCustomers.map(el => {
            //   return {
            //     ...el,
            //     checked: false
            //   }
            // })
          })
    },
    getCustomers() {
      this.$useJwt.customCustomers({URL: '', method: 'get'}, {params: this.params})
          .then(res => {
            res.data.data.map(el => {
              el.checked = true
            })
            this.customers = res.data
            // this.allCustomers.data = res.data.data.map(el => {
            //     return {
            //         ...el,
            //         checked : false
            //     }
            // })
          })
    },
    updateCustomerSegment() {
      this.$refs.segmentForm.validate().then(res => {
        if (res) {
          this.processing = true
          const payload = JSON.parse(JSON.stringify(this.singleSegment))
          delete payload.club_account
          delete payload.customers
          this.$useJwt.customSegment({URL: `/customers/${this.singleSegment.uuid}`, method: 'put'}, payload)
              .then(res => {
                this.processing = false
                this.popupMsg(
                    this.$t('Message.Success'),
                    res.data[this.message],
                    'CheckIcon',
                    'success'
                );
              })
              .catch(err => {
                this.processing = false
                if (err.response.data && err.response.data && err.response.data.message) {
                  if (err.response.data.message.ErrorInformation) {
                    this.popupMsg(
                        this.$t('Message.Failed'),
                        err.response.data.message.ErrorInformation.message,
                        'AlertTriangleIcon',
                        'danger'
                    );
                  } else {
                    this.popupMsg(
                        this.$t('Message.Failed'),
                        err.response.data.message,
                        'AlertTriangleIcon',
                        'danger'
                    );
                  }
                } else {
                  this.popupMsg(
                      this.$t('Message.Failed'),
                      err.response.data[this.message],
                      'AlertTriangleIcon',
                      'danger'
                  );
                }
                if (err.response.status === 403) {
               window.Bus.$emit('trigger-access-denied', {'data': err.response.data})
                }
              })
        }
      });
    },
    computeSelect(event, key) {
      this.singleSegment[key] = event.value
    },
    searchAccounts(search, loading) {
      if (search.length) {
        loading(true);
        this.searchRemoteAccounts(loading, search, this);
      }
    },
    searchRemoteAccounts: debounce(function (loading, search, vm) {
      this.$useJwt.getAllRegisters(`accounts?search=${escape(search)}`)
          .then(res => {
            this.accountResults = res.data.results
            loading(false);
          })
          .catch(err => {
          })
    }, 350),
    changeAccounts(account, key) {
      this.singleSegment[key] = account.number
    },
    async getAllRegisters() {
      const waysOfDelivery = await this.$useJwt.getAllRegisters('wayofdelivery')
      const deliveryTerms = await this.$useJwt.getAllRegisters('termsofdelivery')
      const termsOfPayment = await this.$useJwt.getAllRegisters('termsofpayment')
      // const printTemplates = await this.$useJwt.getAllRegisters('printtemplates')
      const priceLists = await this.$useJwt.getAllRegisters('pricelists')
      const currencies = await this.$useJwt.getAllRegisters('currencies')
      const costcenter = await this.$useJwt.getAllRegisters('costcenters')
      const projects = await this.$useJwt.getAllRegisters('projects')

      this.waysOfDelivery = waysOfDelivery.data.results.map(el => {
        return {label: el.description, value: el.code}
      })
      this.deliveryTerms = deliveryTerms.data.results.map(el => {
        return {label: el.description, value: el.code}
      })
      this.termsOfPayment = termsOfPayment.data.results.map(el => {
        return {label: el.description, value: el.code}
      })
      // this.printTemplates = printTemplates.data.results.map(el => { return { label : el.type , value : el.name } })
      this.priceLists = priceLists.data.results.map(el => {
        return {label: el.description, value: el.code}
      })
      this.currencies = currencies.data.results.map(el => {
        return {label: el.description, value: el.code}
      })
      this.costCenter = costcenter.data.results.map(el => {
        return {label: el.description, value: el.code}
      })
      this.projects = projects.data.results.map(el => {
        return {label: el.description, value: el.code}
      })
    },
    removeSegment() {
      this.sendingModalAction = true
      this.$useJwt.customSegment({URL: `/customers/${this.singleSegment.uuid}`, method: 'delete'})
          .then(res => {
            this.getCustomersSegment();
            this.sendingModalAction = false
            this.$bvModal.hide('modal-remove-segment')
            this.$router.replace({name: 'acountingCustomers'})
            this.popupMsg(
                this.$t('Message.Success'),
                res.data[this.message],
                'CheckIcon',
                'success'
            );
          })
          .catch(err => {
            this.sendingModalAction = false
            if (err.response.data && err.response.data && err.response.data.message) {
              if (err.response.data.message.ErrorInformation) {
                this.popupMsg(
                    this.$t('Message.Failed'),
                    err.response.data.message.ErrorInformation.message,
                    'AlertTriangleIcon',
                    'danger'
                );
              } else {
                this.popupMsg(
                    this.$t('Message.Failed'),
                    err.response.data.message,
                    'AlertTriangleIcon',
                    'danger'
                );
              }
            } else {
              this.popupMsg(
                  this.$t('Message.Failed'),
                  err.response.data[this.message],
                  'AlertTriangleIcon',
                  'danger'
              );
            }
          })
    },
    openRemoveCustomerModal(item, bulk = false) {
      if (bulk) {
        this.single = false
        this.bulk = true
      } else {
        this.customerSelected = item
        this.single = true
        this.bulk = false
      }
      this.$bvModal.show('modal-remove-customer-segment')
    },
    removeCustomerFromSegment() {
      this.sendingModalAction = true
      const payload = {
        customers: []
      }
      if (this.single) {
        payload.customers.push(this.customerSelected.uuid)
      } else {
        const array = []
        this.markCustomers.map(el => {
          array.push(el.uuid)
        })
        payload.customers = array
      }

      this.$useJwt.customSegment({
        URL: `/customers/${this.singleSegment.uuid}/remove_customers`,
        method: 'put'
      }, payload)
          .then(res => {
            this.getSingleSegment(this.$route.params.id)
            this.customerSegments()
            this.sendingModalAction = false
            this.bulk = false
            this.single = false
            this.markCustomers = []
            this.$bvModal.hide('modal-remove-customer-segment')
            this.popupMsg(
                this.$t('Message.Success'),
                res.data[this.message],
                'CheckIcon',
                'success'
            );
          })
          .catch(err => {
            this.sendingModalAction = false
            if (err.response.data && err.response.data && err.response.data.message) {
              if (err.response.data.message.ErrorInformation) {
                this.popupMsg(
                    this.$t('Message.Failed'),
                    err.response.data.message.ErrorInformation.message,
                    'AlertTriangleIcon',
                    'danger'
                );
              } else {
                this.popupMsg(
                    this.$t('Message.Failed'),
                    err.response.data.message,
                    'AlertTriangleIcon',
                    'danger'
                );
              }
            } else {
              this.popupMsg(
                  this.$t('Message.Failed'),
                  err.response.data[this.message],
                  'AlertTriangleIcon',
                  'danger'
              );
            }
          })
    },
    addCustomersToSegment() {
      this.sendingModalAction = true
      const payload = {
        customers: []
      }
      const array = []
      this.markAllCustomers.map(el => {
        array.push(el.uuid)
      })
      this.markAllSegment.map(el => {
        array.push(el.uuid)
      })
      payload.customers = array

      this.$useJwt.customSegment({URL: `/customers/${this.singleSegment.uuid}/add_customers`, method: 'put'}, payload)
          .then(res => {
            this.sendingModalAction = false
            this.markAllCustomers = []
            this.markAllSegment = []
            this.$bvModal.hide('modal-add-customer-segment')
            this.tabSwitch = 1
            this.getAllRegisters()
            this.getSingleSegment(this.$route.params.id)
            this.customerSegments()
            this.popupMsg(
                this.$t('Message.Success'),
                res.data[this.message],
                'CheckIcon',
                'success'
            );
          })
          .catch(err => {
            this.sendingModalAction = false
            if (err.response.data && err.response.data && err.response.data.message) {
              if (err.response.data.message.ErrorInformation) {
                this.popupMsg(
                    this.$t('Message.Failed'),
                    err.response.data.message.ErrorInformation.message,
                    'AlertTriangleIcon',
                    'danger'
                );
              } else {
                this.popupMsg(
                    this.$t('Message.Failed'),
                    err.response.data.message,
                    'AlertTriangleIcon',
                    'danger'
                );
              }
            } else {
              this.popupMsg(
                  this.$t('Message.Failed'),
                  err.response.data[this.message],
                  'AlertTriangleIcon',
                  'danger'
              );
            }
          })
    },
    performAllChecked(e) {
      this.customerResults.forEach(element => {
        element.checked = this.selected === 'selected' ? true : false
      })

      const filter = this.customerResults.filter(item => item.checked)
      if (filter.length > 0) {
        this.markCustomers = filter
      } else {
        this.markCustomers = []
      }
    },
    performSingleChecked(e) {
      const filter = this.customerResults.filter(item => item.checked)
      if (filter.length > 0) {
        this.markCustomers = filter
      } else {
        this.markCustomers = []
      }
    },
    performAllCustomerChecked(e) {
      this.allCustomers.data.forEach(element => {
        element.checked = this.selected === 'selected' ? true : false
      })
      this.performAllSingleChecked(e)
    },
    performAllSingleChecked(e) {
      const filter = this.allCustomers.data.filter(item => item.checked)
      if (filter.length > 0) {
        this.markAllCustomers = filter
      } else {
        this.markAllCustomers = []
      }
    },
    performAllSegmentChecked(e) {
      this.segmentCustomers.forEach(element => {
        element.checked = this.selectedCustomerSegment === 'selected' ? true : false
      })
      this.performAllSingleSegmentChecked(e)
    },
    performAllSingleSegmentChecked(e) {
      const filter = this.segmentCustomers.filter(item => item.checked)
      if (filter.length > 0) {
        this.markAllSegment = filter
      } else {
        this.markAllSegment = []
      }
    },
    firePagination(number) {
      this.currentPage = number
      this.params.page = number;
      // this.getCustomers();
    },
    openAddModal() {
      this.$bvModal.show('modal-add-customer-segment')
    }
  },


  async mounted() {
    this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', false)
    this.getAllRegisters()
    // this.getCustomers()
    const {id} = this.$route.params
    const {redirect} = this.$route.query
    if (id) {
      if (redirect) {
        this.tabSwitch = 2
      }
      await this.getSingleSegment(id)
      await this.getCustomers()
      await this.customerSegments()
    }
  },
};
</script>

<style lang='scss'>
@import '@core/scss/vue/libs/vue-select.scss';

</style>
